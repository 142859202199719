<template>
  <div class="searchBox">
    <h3>
      搜索
      <span>PROJECT</span>
    </h3>
    <div class="search">
      <input type="text" v-model="valueText" placeholder="按分类检索" />
      <img @click="twoSearch" class="searchImg" src="../assets/images/icon_search.png" alt />
    </div>
    <div class="searchLists">
      <ul>
        <li v-for="(item,index) in searchListdata" :key="index">
          <router-link
            v-if="ajaxUrl=='/wc/proLists'"
            :to="{ name: 'projectInfo', params: { id: item.id }}"
          >{{item.title}}</router-link>
          <router-link
            v-if="ajaxUrl=='/wc/news'"
            :to="{ name: 'newsInfo', params: { id: item.id }}"
          >{{item.title}}</router-link>
          <router-link
            v-if="ajaxUrl=='/wc/dongs'"
            :to="{ name: 'observeInfo', params: { id: item.id }}"
          >{{item.title}}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchListdata: [],
      valueText: this.$route.params.value,
    };
  },

  computed: {
    ajaxUrl() {
      return this.$route.params.ajaxUrl;
    },
    value() {
      return this.$route.params.value;
    },
  },
  methods: {
    getSearchLists() {
      this.axios
        .get(this.GLOBAL.serverSrc + this.ajaxUrl + "?keyword=" + this.value)
        .then((res) => {
          console.log(res);
          console.log(res.data.data.data.length > 0);

          if (res.data.data.data.length > 0) {
            this.searchListdata = res.data.data.data;
          } else {
          }
        });
    },
    twoSearch() {

      this.axios
        .get(this.GLOBAL.serverSrc + this.ajaxUrl + "?keyword=" + this.valueText)
        .then((res) => {
          console.log(res);
          console.log(res.data.data.data.length > 0);

          if (res.data.data.data.length > 0) {
            this.searchListdata = res.data.data.data;
          } else {
          }
        });
    },
  },
  mounted() {
    this.getSearchLists();
    console.log(this.ajaxUrl);
    console.log(this.value);
  },
};
</script>

<style lang="scss" scoped>
.searchBox {
  width: 100%;
  padding: 60px 150px;
  > h3 {
    font-weight: 600;
    font-size: 22px;
    color: #000;
    span {
      margin-left: 30px;
      font-weight: 800;
    }
  }
  .search {
    margin-top: 150px;
    width: 100%;
    border-bottom: 1px solid #ccc;
    input {
      width: 90%;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      padding-left: 2px;
    }
    .searchImg {
      width: 40px;
      height: 40px;
      padding: 10px;
      float: right;
    }
  }
  .searchLists {
    margin-top: 0px;

    ul {
      ::-webkit-scrollbar {
        display: block;
        width: 8px;
        height: 8px;
      }
      height: 590px;
      overflow-x: hidden;
      overflow-y: scroll;
      li a {
        font-size: 16px;
        color: #000;
        font-weight: 600;
        line-height: 80px;
        width: 100%;
        border-bottom: 1px solid #ccc;
      }
    }
  }
}
</style>