<template>
  <div class="searchView">
    <Search></Search>
  </div>
</template>

<script>
import Search from "@/components/search.vue";

export default {
  data() {
    return {
    };
  },
  components: {
    Search,
  },
};
</script>

<style lang="scss" scoped>
.searchView {
  width: 100%;
  height: 100%;
}
</style>